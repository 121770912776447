.add-device-modal {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-gap: 12px 0px;
}

.add-device-left {
  line-height: 32px;
}

.edit-device-modal {
  display: flex;
  justify-content: center;
}

.copyCanvasUrl {
  border: 2px solid #d9d9d9;
  padding: 0px 10px;
  cursor: pointer
}

.copyCanvasUrl:hover {
  border: 2px solid #40a9ff;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow:  0 0 0 2px #1890ff33;
}

.viewSettingIcon {
  margin: 7px 10px;
  font-size: 20px;
  color: gray;
}

.viewSettingIcon:hover {
  color:  #40a9ff;
}

.wordColorButton {
  border: 1px solid #F0F0F0;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.wordColorButton:hover {
  /*border: 2px solid #40a9ff;*/
  outline: 2px solid #40a9ff;
}

.wordColorButton {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.info-key {
  width: 24%;
  margin-right: 3px !important;
  min-width: 100px;
  font-weight: 500;
}

.info-value {
  width: 75%;
  min-width: 200px;

  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
